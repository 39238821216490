import React, { useState, useEffect, useRef } from 'react';

interface BannerProps {
  images?: string[];
}

const Banner: React.FC<BannerProps> = ({ 
  images = ["https://ixnpe.s3.ap-south-1.amazonaws.com/movies/waterSplash.jpg"]
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isHovered && !isDragging) {
      const interval = setInterval(() => {
        handleNext();
      }, 8000);
      return () => clearInterval(interval);
    }
  }, [isHovered, isDragging, currentImageIndex]);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => {
      if (prevIndex === images.length - 1) {
        setTimeout(() => {
          const container = containerRef.current;
          if (container) {
            container.style.transition = 'none';
            setCurrentImageIndex(0);
            setTimeout(() => {
              if (container) {
                container.style.transition = 'transform 0.5s ease-in-out';
              }
            }, 50);
          }
        }, 500);
        return prevIndex;
      }
      return prevIndex + 1;
    });
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => {
      if (prevIndex === 0) {
        return prevIndex;
      }
      return prevIndex - 1;
    });
  };

  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    setIsDragging(true);
    const pageX = 'touches' in e ? e.touches[0].pageX : e.pageX;
    setStartX(pageX);
  };

  const handleDragMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging) return;
    
    e.preventDefault();
    const pageX = 'touches' in e ? e.touches[0].pageX : e.pageX;
    const walk = (startX - pageX) * 2;
    const containerWidth = containerRef.current?.offsetWidth || 0;
    
    if (Math.abs(walk) > containerWidth / 4) {
      if (walk > 0 && currentImageIndex < images.length - 1) {
        handleNext();
        setIsDragging(false);
      } else if (walk < 0 && currentImageIndex > 0) {
        handlePrev();
        setIsDragging(false);
      }
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  return (
    <section 
      className="relative max-w-6xl mx-auto overflow-hidden "
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        ref={containerRef}
        className="relative flex touch-pan-x h-48 sm:h-60 md:h-72 lg:h-96"
        style={{
          transform: `translateX(-${currentImageIndex * 100}%)`,
          transition: 'transform 0.5s ease-in-out',
          cursor: isDragging ? 'grabbing' : 'grab'
        }}
        onMouseDown={handleDragStart}
        onMouseMove={handleDragMove}
        onMouseUp={handleDragEnd}
        onMouseLeave={handleDragEnd}
        onTouchStart={handleDragStart}
        onTouchMove={handleDragMove}
        onTouchEnd={handleDragEnd}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className="w-full flex-shrink-0"
            style={{ userSelect: 'none' }}
          >
            <img
              src={image}
              alt={`Banner ${index + 1}`}
              className="w-full h-full object-cover"
              draggable={false}
            />
          </div>
        ))}
      </div>
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
        {images.map((_, index) => (
          <button
            key={index}
            className={`h-2 w-2 rounded-full transition-colors duration-300 ${
              index === currentImageIndex ? 'bg-blue-500' : 'bg-gray-300'
            }`}
            onClick={() => setCurrentImageIndex(index)}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </section>
  );
};

export default Banner;