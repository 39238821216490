export const bg = require('../assets/images/treaz.png');
export const bg2 = require('../assets/images/bgrnd2.jpg');
export const banner = require('https://ixnpe.s3.ap-south-1.amazonaws.com/movies/waterSplash.jpg');
export const logo = require('../assets/images/logoTREAZ.png');
export const bottles = require('../assets/images/bottles.jpeg');
export const custombottle = require('../assets/images/custom-bottle.jpeg');
export const bottlelogo = require('../assets/images/bottlelogo.jpeg');
export const weddings = require('../assets/images/Marrage1.png');
export const birthday = require('../assets/images/Birthday.png');
export const Hotelgreen = require('../assets/images/Hotelgreen.png');
export const TreazX = require('../assets/images/blaackBottleBlue.png');
export const business3 = require('../assets/images/business3.png');
export const KIA = require('../assets/images/kIA.png');
