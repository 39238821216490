import React, { useState } from 'react';
import { bottlelogo } from '../utils/data';
import { weddings } from '../utils/data';
import { birthday } from '../utils/data';
import { Hotelgreen } from '../utils/data';
import { business3 } from '../utils/data';
import { KIA } from '../utils/data';

const categories = [
  'All',
  'Weddings',
  'Conferences',
  'Events',
  'Hotels',
  'Healthcare'
];

const services = [
  { name: 'Custom Branded Wedding Bottle', image: weddings, category: 'Weddings' },
  { name: 'Custom Branded Conferences Bottle', image: business3, category: 'Conferences' },
  { name: 'Custom Branded Birthday Bottle', image: birthday, category: 'Events' },
  { name: 'Custom Branded Hotel Green Bottle', image: Hotelgreen, category: 'Hotels' },
  { name: 'Custom KIA Branded Bottle ', image: KIA, category: 'Conferences' },
];

const Services: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');

  const filteredServices = selectedCategory === 'All'
    ? services
    : services.filter(service => service.category === selectedCategory);

  return (
    <section id="services" >
      <div className="container mx-auto md:px-4  md:-mt-5 mt-0 ">
        <h2 className="text-xl md:text-4xl font-bold mb-8 text-center text-white ">
          Our Services
        </h2>
        <p className="text-lg md:text-xl text-center mb-12 text-white max-w-3xl mx-auto">
          We specialize in customizing packed water with your own brand for a unique touch. 
          Enhance your brand visibility and offer a personalized experience to your customers.
        </p>
        
        <div className="flex flex-wrap justify-center mb-8">
          {categories.map(category => (
            <button
              key={category}
              className={`px-4 py-2 m-1 rounded-lg font-medium transition-colors duration-300 ${selectedCategory === category ? 'bg-green-800 text-white' : 'bg-white text-indigo-800 border border-indigo-800'}`}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>
        <div className="relative w-full px-4 md:px-0">
          <div className="overflow-x-auto pb-4 -mx-4 md:mx-0" style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
            <style>{`
              .overflow-x-auto::-webkit-scrollbar {
                display: none;
              }
            `}</style>
            <div className="flex space-x-6 md:space-x-8 px-4 md:px-40">
              {filteredServices.map((service, index) => (
                <div key={index} className="w-48 md:w-56 flex-shrink-0 mb-6">
                  <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg transition-transform duration-300 hover:shadow-xl">
                    <img 
                      src={service.image} 
                      alt={service.name} 
                      className="w-full h-32 md:h-40 mb-4 rounded object-cover"
                    />
                    <p className="text-center text-lg font-semibold text-green-800">
                      {service.name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;