import React from 'react';
import Banner from '../components/Banner';
import HomeProducts from '../components/HomeProducts';
import About from '../components/About';
import Services from '../components/Services';
import Details from '../components/Details';
import Testimonials from '../components/Testimonials';
import Contact from '../components/Contact';

const GlowingOrb = () => (
  <div className="absolute rounded-full blur-3xl opacity-30 animate-float" 
       style={{
         width: Math.random() * 300 + 200 + 'px',
         height: Math.random() * 300 + 200 + 'px',
         left: Math.random() * 100 + '%',
         top: Math.random() * 100 + '%',
         background: `radial-gradient(circle at center, 
           ${['#4F46E5', '#7C3AED', '#2563EB', '#0EA5E9'][Math.floor(Math.random() * 4)]}, 
           transparent)`,
         animationDelay: Math.random() * 5 + 's',
         animationDuration: Math.random() * 10 + 15 + 's'
       }}>
  </div>
);

const HomePage: React.FC = () => {
  return (
    <div className="relative overflow-x-hidden min-h-screen">
      <div className="fixed inset-0 bg-gradient-to-br from-gray-900 via-black to-gray-900">
        <div className="absolute inset-0 bg-gradient-to-t from-indigo-900/20 via-purple-900/20 to-blue-900/20 animate-gradient"></div>
        <div className="absolute inset-0 overflow-hidden">
          {[...Array(8)].map((_, i) => (
            <GlowingOrb key={i} />
          ))}
        </div>
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-indigo-900/30 via-transparent to-transparent"></div>
        <div className="absolute inset-0 opacity-[0.015] bg-noise"></div>
      </div>
      <div className="relative z-10">
        <section id="home" className="pt-14 backdrop-blur-sm bg-black/20"> 
          <Banner />
        </section>
        <section id="products" className="px-4 md:px-0 pt-6 backdrop-blur-sm bg-black/20">
          <HomeProducts />
        </section>
        <section id="services" className="px-4 md:px-0 pt-8 backdrop-blur-sm bg-black/20">
          <Services />
        </section>
        <section id="about" className="px-4 md:px-0 pt-16 backdrop-blur-sm bg-black/20 ">
          <About />
        </section>
        <section id="testimonials" className="px-4 md:px-0 pt-16 backdrop-blur-sm bg-black/20">
          <Testimonials />
        </section>
        <section id="details" className="px-4 md:px-0 pt-16 backdrop-blur-sm bg-black/20">
          <Details />
        </section>
        <section id="contact" className="px-4 md:px-0 pt-16 backdrop-blur-sm bg-black/20">
          <Contact />
        </section>
      </div>
    </div>
  );
};

export default HomePage;
