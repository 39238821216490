import React from 'react';

const GettingStarted: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-6">Getting Started with Us</h1>
        <ol className="list-decimal list-inside space-y-4">
          <li>Choose your bottle size and shape. Please note that label sizes are standard and fixed.</li>
          <li>Read and agree to our terms and conditions. Once agreed, transfer the refundable security deposit for your labels. This will be refunded once all labels are used.</li>
          <li>Email us your original logo file in any of the following formats: CorelDRAW, PDF, Adobe Illustrator, or Photoshop.</li>
          <li>Fill out and send the Label KYC Form, including reference images and all details you need on your label.</li>
          <li>Fill out and send the Billing KYC Form, including delivery landmarks, mobile numbers, and contact person details.</li>
          <li>Receive two label design options within 7 days after submitting your filled Label KYC Form and original logo.</li>
          <li>Once you approve the final label design, we will send them for bulk printing. Expect delivery within 7–10 days from the date of approval.</li>
          <li>Ensure payment online or upon delivery to our driver.</li>
          <li>For repeat orders, a 7-day lead time is required. Please plan accordingly to avoid delays.</li>
        </ol>

        <h2 className="text-2xl font-bold mt-8 mb-4">Terms & Conditions</h2>
        <ul className="list-disc list-inside space-y-4">
          <li>Free delivery is provided within a 5 km radius of Kushalnagar for a minimum order of 100 cases.</li>
          <li>A refundable security deposit of ₹10,000/- per bottle size is required for investing in your customized labels. The deposit will be refunded only after your label stock is fully utilized.</li>
          <li>All label sizes are standardized and fixed.</li>
          <li>For special labels like 100% transparent designs, the minimum order quantity and base prices will be slightly higher.</li>
          <li>Standard delivery requires a lead time of 7 days. For certain orders, delivery may take up to 9 days.</li>
          <li>We primarily work on a 100% advance payment basis through Cash, Cheque, or UPI.</li>
          <li>For one-time events or orders, the minimum order quantity is 1,000 bottles. The price per bottle will be ₹2/- higher than standard prices.</li>
          <li>For customized bottles, the delivered quantity may vary by up to 10% (more or less) from the order. Any variation in the delivered quantity must be accommodated, and dues should be settled accordingly.</li>
          <li>Prices are exclusive of GST at 18%.</li>
          <li>Once the label design is finalized, any redesign or reprint will incur additional charges.</li>
        </ul>
      </div>
    </div>
  );
};

export default GettingStarted;
