import React, { useState, useEffect, useRef } from 'react';
import { Star } from 'lucide-react';

interface Review {
  id: string;
  name: string;
  rate: number;
  review: string;
  created_at: string;
}

interface ApiResponse {
  success: boolean;
  data: Review[];
}

const AllReviews = () => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch('https://dev-a1.appbrainz.com/api/reviews');
        const result: ApiResponse = await response.json();
        
        if (result.success) {
          setReviews(result.data);
          // Scroll to top after setting reviews
          if (componentRef.current) {
            componentRef.current.scrollIntoView({ 
              behavior: 'smooth',
              block: 'start'
            });
          }
        } else {
          setError('Failed to load reviews');
        }
      } catch (err) {
        setError('Error fetching reviews');
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return <div className="p-4">Loading reviews...</div>;
  }

  if (error) {
    return <div className="p-4 text-red-500">{error}</div>;
  }

  const averageRating = reviews.length
    ? (reviews.reduce((acc, review) => acc + review.rate, 0) / reviews.length).toFixed(1)
    : '0.0';

  return (
    <div ref={componentRef} className="w-full max-w-4xl mx-auto p-4">
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Product Reviews</h2>
        <div className="flex items-center gap-2 mb-2">
          <span className="text-xl font-semibold">{averageRating}</span>
          <div className="flex">
            {[...Array(5)].map((_, i) => (
              <Star
                key={i}
                className={`w-5 h-5 ${
                  i < Math.round(Number(averageRating))
                    ? 'text-yellow-400 fill-yellow-400'
                    : 'text-gray-300'
                }`}
              />
            ))}
          </div>
        </div>
        <p className="text-gray-600">Based on {reviews.length} reviews</p>
      </div>

      <div className="space-y-4">
        {reviews.map((review) => (
          <div key={review.id} className="border rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-semibold">{review.name}</h3>
              <span className="text-gray-500 text-sm">
                {formatDate(review.created_at)}
              </span>
            </div>
            <div className="flex mb-2">
              {[...Array(5)].map((_, i) => (
                <Star
                  key={i}
                  className={`w-4 h-4 ${
                    i < review.rate
                      ? 'text-yellow-400 fill-yellow-400'
                      : 'text-gray-300'
                  }`}
                />
              ))}
            </div>
            <p className="text-gray-700">{review.review}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllReviews;